import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import privacyPolicy from '../assets/images/privacy-policy.jpg'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Privacy Policy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy Policy" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="privacy-policy-content">
                            
                            <p><i>Last updated: 27/4/2021</i></p>
                                <p>This Privacy Policy is intended to explain how we process the personal data you provide to us:
                                    <ul><li>by filling out the contact form on our website www.gnucoop.com (hereinafter the "Website") or</li>
                                    <li>by getting in touch with our offices through any channel (telematic, telephone, postal, etc.).</li>.
                                    </ul>

                                <p>Gnucoop Soc. Coop., as the owner of the treatment, guarantees and constantly ensures that your personal data are always treated 
                                    in compliance with the requirements of EU Regulation 2016/679 ("GDPR") and Legislative Decree no. 196/2003 (Italian Privacy Code as amended 
                                    by Legislative Decree 101/2018) regarding data protection.</p>.

                                <p>This privacy policy is, therefore, intended to explain to you the ways in which our Company collects, uses, discloses, 
                                    stores and possibly transfers your personal data, pursuant to and for the purposes of Art. 13 GDPR.</p>
</p>
                                <h3>A. Identity and contact details of the Data Controller</h3>.
                                <blockquote className="blockquote">
                                    <p>The Data Controller is Gnucoop Soc. Coop. with registered office in Milan, Via Michele Faraday 20, 20143, Tax Code and VAT number 07931480961 (hereinafter the "Company" or "Data Controller" or "Gnucoop").
                                    For any request regarding the processing of your personal data, please write to <a href="mailto:dpo@gnucoop.com">dpo@gnucoop.com</a> 
                                    </p>
                                </blockquote>
                                <h3>B. Purpose of processing, legal basis and legitimate interest</h3>.
                                The processing of your personal data by Gnucoop may take place on the basis of:
                                        <ol>
                                            <li>your consent (for example, by filling out the contact form on the website or by contacting our offices for information, advice, orders, sending CVs, etc.);</li>.
                                            <li>to a contract (if you are, for example, customers, suppliers or consultants of our Company);</li>
                                            <li>to a legal obligation (when our Company is required to fulfill specific obligations deriving from legal regulations);</li>
                                            <li>to a legitimate interest of the Company (in case of fulfillments strictly related to the relationship you have established with our Company), always taking into account the necessary balance between our interest and your rights and freedoms.</li>
                                        </ol>
                                    
                                <h3>C. Protection of your data and possible recipients of the same</h3>
                                <p>Your personal data is processed exclusively by our personnel, authorized and trained to process such data and, in any case, under the responsibility of the Data Controller and exclusively for the purposes in relation to which your data was collected.
                                        Gnucoop takes appropriate and adequate technical and organizational measures to ensure the security of your personal data, protecting them from loss, theft and/or abuse, as well as from unauthorized access, disclosure, alteration and/or destruction, whether intentional or accidental.
                                        Your personal data is stored exclusively at our Company and is not communicated or transmitted to third parties without your prior consent.
                                        Gnucoop may, however, be obliged in some cases to transmit your personal data, in whole or in part, to third parties as a result of legal obligations, judicial proceedings of any kind or kind and/or requests by public authorities.
                                </p>
                                <h3>D. Newsletter</h3>
                                <p>The personal data that you communicate to us by subscribing to our newsletter are:</p>
                                <ul>
                                    <li>First name</li>
                                    <li>Surname</li>
                                    <li>email</li>
                                </ul>
                                <p>We process this data on the basis of your express consent and will retain it until you freely decide to revoke it.
                                In order to send our newsletter, your contact data is also processed by mailchimp, a marketing automation operator that plays the role of Data Processor pursuant to Art. 28 GDPR.
                                We have chosen mailchimp because, subject to the consent of the person concerned, it guarantees security measures, technical and organizational, appropriate to the protection of personal data of our users and imposes strict rules against abuse to its customers and users.
                                Link to privacy mailchimp</p>

                                <h3>E. Data transfer to third countries</h3>
                                <p>Gnucoop does not transfer your data to third countries or to International Organizations, 
                                    without adequate protection of a technical, organizational, regulatory and contractual nature, in accordance with current legislation.</p>

                                <h3>G. Rights of the interested party</h3>
                                <p>You may write to the contact address indicated in "Section A" above of this notice to exercise the following rights:
                                </p>
                                <ol>
                                    <li>right of access to personal data; right to obtain the rectification or cancellation of the same or the limitation of the processing concerning them;</li>
                                    <li>right to object to processing;</li>
                                    <li>right to data portability, in relation only to data in electronic format, within the limits established by art. 20 of the GDPR.</li>
                                </ol>
                                <p>In relation to data for which the legal basis for processing is your consent, you have the right to revoke such consent at any time, 
                                    without affecting the lawfulness of the processing carried out by the Controller and based on the consent provided by you before the revocation.</p>
                                <h3>H. Complaint</h3>
                                <p>We also inform you that you have the right, under the conditions, to lodge a complaint with the territorially competent supervisory authority, as identified by Articles 3, 55 and 56 GDPR. 
                                For further information, please visit the institutional site of the Guarantor of the protection of personal data at the following web address: <a href="www.garanteprivacy.it" target="_blank"> www.garanteprivacy.it </a>
                                </p>
                                <h3>I. Communications and data transfer</h3>
                                <p>Subject to the above, the communication of your personal data may constitute a legal obligation and/or a contractual obligation and, in any case, a necessary requirement for the conclusion and execution of the commitments undertaken by our Company.
                                This means that, in these cases, the failure to provide your data could make it impossible for our Company to follow up on your requests.
                                </p>
                                <p>For further information in relation to the collection of your personal data as a result of your visit to our website, please also read our cookie policy.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                Chi siamo
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact us
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default PrivacyPolicy;